import React, { useRef } from "react";
import Layout from "@components/Layout";
import BgImage from "gatsby-background-image";
import { graphql, useStaticQuery } from "gatsby";
import Video from "@components/Video";
import Slider from "react-slick";
import ArrowLeftCircle from "@assets/images/icon/arrow-circle-left-white.svg";

import css from "@cssmodules/about.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TentangKami = () => {
  const img = useStaticQuery(graphql`
    query AboutQuery {
      bgAboutDesktop: file(
        relativePath: { eq: "bg/desktop/bg-hero-tentang-kami.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1020, maxWidth: 2010, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bgAboutMobile: file(
        relativePath: { eq: "bg/mobile/bg-tentang-kami.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 680, maxWidth: 350, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const sliderRef = useRef();

  const bg = [
    img.bgAboutDesktop.childImageSharp.fluid,
    {
      ...img.bgAboutMobile.childImageSharp.fluid,
      media: "(max-width: 1023.98px)",
    },
  ];

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    vertical: true,
    arrows: false,
    swipe: false,
    verticalSwiping: false,
    swipeToSlide: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: css.heroSlider,
  };

  return (
    <Layout noFooter>
      <BgImage fluid={bg} className={css.bgAbout}>
        <Slider {...sliderSettings} ref={sliderRef}>
          <div className={css.about}>
            <div className={css.video}>
              <Video
                link="https://www.youtube.com/watch?v=epEB_llv9NY"
                quality="sd"
                imgStyle={{ borderRadius: 0 }}
              />
            </div>
            <div className={css.title}>
              <h3>
                <span style={{ color: "#f3611b" }}>Siapa</span>kah Kami?
              </h3>
            </div>
            <div className={css.textContainer1}>
              <p>
                Kami adalah konveksi bikin jaket online no 1 di Indonesia. Kami
                berdiri sejak tahun 2014 di Depok, Jawa Barat.
              </p>
              <p>
                Kami mempunyai misi untuk membahagiakan para pekerja dan juga
                komunitas, dengan menciptakan pakaian yang nyaman dikenakan
                dalam kegiatan sehari-hari.
              </p>
            </div>
            <div className={css.textContainer2}>
              <p>
                Terletak di Jakarta, kami adalah perusahaan konveksi seragam
                yang memiliki pengalaman bertahun-tahun menangani kebutuhan
                seragam kerja, seragam kantor, seragam pabrik, ser- agam
                pertambangan / wearpack dan berbagai jenis seragam lainnya.Kami
                memiliki tim layanan pelanggan yang mampu merespons dengan cepat
                semua pertanyaan dan hal-hal yang ingin diketahui klien. Dengan
                pemilihan yang ketat yang kami lakukan, kami telah memiliki
                penjahit berseragam yang memiliki jahitan kualitas penjahit yang
                rapi. Ditambah dengan tim kontrol kualitas yang berpengalaman,
                kami dapat menjamin dan memastikan bahwa barang yang dipesan
                sesuai dengan keinginan klien, kerapian, ukuran dan model ser-
                agam. Untuk mengatasi masalah ketika bekerja pada bentangan
                seragam, kami telah membentuk tim manajemen produksi yang
                terorganisir dengan baik yang mampu mengirim- kan pesanan klien
                tepat waktu.
              </p>
              <p>
                Kami memiliki kapasitas produksi yang besar, sehingga untuk
                dapat melayani banyak klien dalam waktu bersamaan bahan yang
                seragam, kami memberikan jaminan material dan warna sesuai
                kesepakatan dengan klien di awal. Dan tentu saja, kami dapat
                memberikan harga yang terjangkau, tanpa mengurangi kualitas
                material dan kualitas jahitannya. Silakan hubungi CS kami, CS
                kami akan dengan senang hati melayani dan menjawab semua
                pertanyaan Anda. Spesialis Seragam Kerja. 1574++ Klien Puas.
                Tepat Waktu. Tempat dan Penjahit Sendiri. Mudah, Cepat,
                Bergaransi. Murah Berkualitas. Gratis Sampel. Gratis Pengiriman.
                Gratis Kunjungan. Gratis Desain. Produsen Langsung. Workshop
                Sendiri. Konveksi seragam ter- percaya, 1574++ Klien puas dan
                terus repeat order dari tahun ke tahun. Workshop milik sendiri
                sehingga Harga kompetitif, kontrol kualitas ketat, tepat waktu.
                4 orang customer service kami siap melayani kebutuhan Anda
                dengan cepat dan ramah.
              </p>
            </div>
            <div className={css.nextSlide} onClick={nextSlide}>
              <img src={ArrowLeftCircle} />
              <span>Baca tentang kami</span>
            </div>
          </div>
          <div className={css.aboutText}>
            <div className={css.textContainer}>
              <p>
                Terletak di Jakarta, kami adalah perusahaan konveksi seragam
                yang memiliki pengalaman bertahun-tahun menangani kebutuhan
                seragam kerja, seragam kantor, seragam pabrik, ser- agam
                pertambangan / wearpack dan berbagai jenis seragam lainnya.Kami
                memiliki tim layanan pelanggan yang mampu merespons dengan cepat
                semua pertanyaan dan hal-hal yang ingin diketahui klien. Dengan
                pemilihan yang ketat yang kami lakukan, kami telah memiliki
                penjahit berseragam yang memiliki jahitan kualitas penjahit yang
                rapi. Ditambah dengan tim kontrol kualitas yang berpengalaman,
                kami dapat menjamin dan memastikan bahwa barang yang dipesan
                sesuai dengan keinginan klien, kerapian, ukuran dan model ser-
                agam. Untuk mengatasi masalah ketika bekerja pada bentangan
                seragam, kami telah membentuk tim manajemen produksi yang
                terorganisir dengan baik yang mampu mengirim- kan pesanan klien
                tepat waktu.
              </p>
              <p>
                Kami memiliki kapasitas produksi yang besar, sehingga untuk
                dapat melayani banyak klien dalam waktu bersamaan bahan yang
                seragam, kami memberikan jaminan material dan warna sesuai
                kesepakatan dengan klien di awal. Dan tentu saja, kami dapat
                memberikan harga yang terjangkau, tanpa mengurangi kualitas
                material dan kualitas jahitannya. Silakan hubungi CS kami, CS
                kami akan dengan senang hati melayani dan menjawab semua
                pertanyaan Anda. Spesialis Seragam Kerja. 1574++ Klien Puas.
                Tepat Waktu. Tempat dan Penjahit Sendiri. Mudah, Cepat,
                Bergaransi. Murah Berkualitas. Gratis Sampel. Gratis Pengiriman.
                Gratis Kunjungan. Gratis Desain. Produsen Langsung. Workshop
                Sendiri. Konveksi seragam ter- percaya, 1574++ Klien puas dan
                terus repeat order dari tahun ke tahun. Workshop milik sendiri
                sehingga Harga kompetitif, kontrol kualitas ketat, tepat waktu.
                4 orang customer service kami siap melayani kebutuhan Anda
                dengan cepat dan ramah.
              </p>
            </div>
            <div className={css.nextSlide} onClick={prevSlide}>
              <img src={ArrowLeftCircle} />
              <span className={css.back}>Kembali untuk menonton video</span>
            </div>
          </div>
        </Slider>
      </BgImage>
    </Layout>
  );
};

export default TentangKami;
